var mobileMenuToggler = $(".js-mobile-menu-toggler");
var mobileMenu = $(".js-menu");

mobileMenuToggler.on('click', function (event) {
  event.preventDefault();
  var selector = $(this).attr('data-menu');
  $('.' + selector).slideToggle(500);
  event.stopPropagation();
});

$(document).on('click', function (event) {
  if ($(event.target).closest(mobileMenu).length) return;
  var width = $(window).width();
  if (width < 992) {
    mobileMenu.slideUp(500);
    mobileMenuToggler.removeClass("active")
    event.stopPropagation();
  }
});


$(window).resize(function () {
  var width = $(window).width();
  if (width > 991 && mobileMenu.is(':hidden')) {
    mobileMenu.removeAttr('style');
  }
}); 