$(function () {
    ymaps.ready(init);
    var myMap;

    function init() {
        myMap = new ymaps.Map("map", {
            center: [56.859409, 35.915552],
            controls: [],
            zoom: 15
        });
        myMap.behaviors.disable('scrollZoom');
        myMap.behaviors.disable('drag');
        myMap.controls.add(new ymaps.control.ZoomControl());
        var myPlacemark = new ymaps.Placemark([56.859409, 35.915552], {
            type: "Point",
            hintContent: 'АтельеФэшн',
            balloonContent: 'АтельеФэшн',
            iconCaption: 'улица Вольного Новгорода 16'
        }, {
            iconColor: '#92489b'
        });


        myMap.geoObjects.add(myPlacemark);
    }
});