
/* 
var header = $('.section--header');
var heightHeader = header.height();
var billboard = $('.section--billboard');

function windowScroll() {
    if ($(this).scrollTop() > 0) {
        header.addClass('header--fixed');
        billboard.addClass('billboard--space');
    }
    else {
        header.removeClass('header--fixed');
        billboard.removeClass('billboard--space');
    }
}

$(window).on('load scroll', windowScroll); */