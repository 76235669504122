var form = $(".js-form");
var fieldName = $('.js-form .js-name');
var fieldPhone = $('.js-form .js-phone');
var fields = $('.js-form .form__control');

var submitButton = form.find('.s-submit');
var msgContainerName = ".js-message";
var msgContainer = $(msgContainerName);

$(function () {
    $.mask.definitions['~'] = "[+-]";
    $('.js-phone').mask('+7 (999) 999-99-99');
});

form.validate({
    ignore: ":hidden:not(.input--ignore-not)",
    errorClass: "form__error",
    errorContainer: msgContainerName,
    errorElement: "span",
    errorLabelContainer: msgContainerName,
    showErrors: function (errorMap, errorList) {

        if (msgContainer.hasClass("jsCheckSendClass")) {
            msgContainer.html('').removeClass("jsCheckSendClass");
        }

        msgContainer.removeClass("form__alert--success").addClass("form__alert--error");
        this.defaultShowErrors();
    },

    rules: {
        name: {
            required: true,
            minlength: 2
        },
        phone: {
            required: true,
            //digits: true,
            minlength: 10
        }
    },
    messages: {
        name: {
            required: "Введите имя.<br>",
            minlength: "Имя должно состоять как минимум из 2-х символов.<br>"
        },
        phone: {
            required: "Введите номер телефона.<br>",
            minlength: "Номер телефона должен состоять как минимум из 10-ти символов.<br>"
        },
        email: "Введите корректный адрес электронной почты.<br>"
    },
    submitHandler: function () {
        var predata = {
            'name': fieldName.val(),
            'phone': fieldPhone.val(),
            'type_form': $(form).attr('data-form-type')
        };
        $.ajax({
            type: "POST",
            url: "/ajax-handler/",
            data: predata,
            success: function (data, textStatus, jqXHR) {
                var response = $.parseJSON(data);
                if (response['status']) { //если отправляется
                    //showPopupMsg();
                    showFormAlert('success', 'Спасибо! Ваша заявка успешно отправлена');
                    $(form).find("input").val("");
                    $(form).trigger("reset");
                } else { //если не отправляется
                    //showPopupMsg("", 'Ошибка отправки сообщения', "error", "OK", '#bd2d28');
                    showFormAlert('error', 'Ошибка отправки сообщения');
                    $(form).find("input").val("");
                    $(form).trigger("reset");
                }

            },
            error: function (data, textStatus, errorThrown) { //если не отправляется
                //showPopupMsg("", data.responseText, "error", "OK", '#bd2d28');
                showFormAlert('error', 'Ошибка отправки сообщения');
                $(form).find("input").val("");
                $(form).trigger("reset");
            }
        });
        return false;
    }
});

function showFormAlert(status, msg) {
    var removeStatus = 'error';

    if (status == 'error') {
        removeStatus = 'success';
    }

    msgContainer.removeClass("form__alert--" + removeStatus)
        .html('')
        .addClass("form__alert--" + status)
        .addClass('jsCheckSendClass')
        .html('<span class="form__'+ status +'">' + msg + '</span>')
        .show();
};

function showPopupMsg(popup_title, popup_text, popup_type, popup_btnText, popup_color) {
    swal({
        title: popup_title || "",
        text: popup_text || 'Спасибо! Ваша заявка успешно отправлена',
        type: popup_type || "success",
        confirmButtonText: popup_btnText || "OK",
        confirmButtonColor: popup_color || '#125e0e'
    });
}