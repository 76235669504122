$('.js-popup-callback').magnificPopup({
    type: 'inline',
    items: {
        src: '#callback'
    },
    focus: '#name'
});

$('.js-popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeBtnInside: false,
    image: {
        titleSrc: function(item) {
            return item.el.children('img').attr('alt');
        }
    },
    gallery: {
        enabled: true,
        tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
    }
});

$('.js-popup-creation').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeBtnInside: false,
    gallery: {
        enabled: true,
        tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
    },
    // arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',

    // tPrev: 'Previous (Left arrow key)',
    // tNext: 'Next (Right arrow key)',
    // tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
});